body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: '#F5D3C8';
}

@font-face {
  font-family: catshop;
  src: url('././fonts/catshop/CatShop.ttf');
  font-display: swap;
}
@font-face {
  font-family: cabin;
  src: url('././fonts/cabin/Cabin-Medium.ttf');
  font-display: swap;
}
@font-face {
  font-family: averia;
  src: url('././fonts/averia/Averia-Regular.ttf');
  font-display: swap;
}

/* Global styles */
body, html { 
  font-family: 'catshop';
}