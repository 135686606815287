:root{
  background-color: #35386F;
}
.App-footer {
  min-height: 11vh;
  display: flex;
  bottom: 0;
  align-items: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
  position: relative;
  padding-left: 20px;
}

.footer-links{ 
  text-decoration: none;
  color: #C4CEEA;
}
.footer-grid{
  padding-left: 20px;
  padding-right: 20px;
}
.footer-link-grid{
  padding-left: 20px;
  padding-right: 20px;
}

.article {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.picture {
  object-fit:cover;
  position: relative;
  width: 100%;
  height: 500px;
 
  /* filter: contrast(1.3) brightness(.5) */
}

.header {
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: #373737;
  position: absolute;
  bottom: 15px;
  background-color: #e5ebf7ae;
  /* margin-left: calc(40px + 2vmin);
  margin-right: 50px; */
  left:0;
  right:0;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  min-height: 90px;
  max-height: 250px;
  border-radius: 5px; 
}

.headerLogo {
  height: 150px;
  position: relative;
  object-fit: cover;
}

.cardBtn {
  min-height: 40px;
}

.imgBtn:hover {
  background-color: #BEB2EE !important;
}

.iconContainer {
  background-color: #dddddd;
  padding: 20px;
  border-radius: 50% 50% 0% 0%;
  box-shadow: 10px 10px #9C30FD;
  min-width: 220px;
  border: #dddddd solid 4px;
  color: #442222;
  height: 90%;
}

.icons {
  width: 100px;
  height: 100px;
  padding: 10px;
}


.iconGrid {
  padding: 20px;
  padding-bottom: 50px;
}

.teamImg{
  position: relative;
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;
  bottom: 20px;
  border: none;
  margin: auto;
  box-shadow: 10px 10px #E4D4BC ;
}
.aboutUsImg {
  width: 350px;
  height: 350px;
  object-fit: cover;
  border-radius: 50%;
  border: none;
  box-shadow: #E4D4BC 10px 10px;
  margin: auto
}

.teamImgSmall{
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;
  border: none;
  box-shadow: #E4D4BC 5px 5px;
  margin: auto
}

.cardBtn:hover {
  background-color:  #BEB2EE !important;
  border-color: #BEB2EE !important;;
  color: #4E57A4 !important;
}

.App-footer {
  background-color: #ebb4abeb;
  background-color: #49f8f2;
}

.menuBtn:hover {
  background-color: #BEB2EE !important;
  border: #BEB2EE solid 3px !important;
  color: #4E57A4 !important;
}

.servicesBtn:hover {
  background-color: #BEB2EE !important;
  border: #BEB2EE solid 5px !important;
  color: #4E57A4 !important;
}

.categoryBtn:hover{
  background-color: #BEB2EE !important;
  border: #BEB2EE solid 2px !important;
  color: #4E57A4 !important;
}


.MuiCard-root {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.css-1m9128y{
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}